import { i18n } from "@/i18n";

const { t } = i18n.global;

interface Product {
  slug: string;
  type: "regular" | "special";
  color: string;
  isNew: boolean;
  text: {
    name: string;
    brand: string;
    description: string;
    age: string;
    body: string;
    ingredients: string;
    preparation: string;
  };
  image: {
    packshot: string;
    packshotThumb: string;
    table: string;
  };
  medals?: string[];
}

function getPreparation( key: string ) {

  const html = t(`product.${key}.preparationTableUrl`) != `product.${key}.preparationTableUrl`
    ? `<img class="table" src="${t(`product.${key}.preparationTableUrl`)}"/>`
    : `${t(`product.${key}.preparationTableExtra`)}`;

    
    const extraPreparationPoints = t(`product.${key}.preparationNumber9`) != `product.${key}.preparationNumber9`
    ? `<br/>
    <strong>9.</strong> ${t(`product.${key}.preparationNumber9`)}<br/>
    <strong>10.</strong> ${t(`product.${key}.preparationNumber10`)}`
    : '';
  
    const preparationInstructions = t(`product.${key}.preparationNumber1`) != `product.${key}.preparationNumber1` ?`<img class="prep-img mx-auto" src="/img/products/preparation-a.svg" />
    <div class="text">
      <strong>1.</strong> ${t(`product.${key}.preparationNumber1`)}<br/>
      <strong>2.</strong> ${t(`product.${key}.preparationNumber2`)}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-b.svg" />
    <div class="text">
      <strong>3.</strong> ${t(`product.${key}.preparationNumber3`)}<br/>
      <strong>4.</strong> ${t(`product.${key}.preparationNumber4`)}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-c.svg" />
    <div class="text">
      <strong>5.</strong> ${t(`product.${key}.preparationNumber5`)}<br/>
      <strong>6.</strong> ${t(`product.${key}.preparationNumber6`)}
    </div>
    <img class="prep-img mx-auto" src="/img/products/preparation-d.svg" />
    <div class="text">
      <strong>7.</strong> ${t(`product.${key}.preparationNumber7`)}<br/>
      <strong>8.</strong> ${t(`product.${key}.preparationNumber8`)}${extraPreparationPoints}
    </div>` : '';

    return `
    <div class="title">
      ${t("product.preparation.tableTitle")}
    </div>
    <div class="table-holder">
      ${html}
    </div> 
    <div class="title">
      ${t("product.preparation.instructions.title")}
    </div>
    <div class="emphasis">
      ${t(`product.${key}.preparationSubtitle`)}
    </div>
    ${preparationInstructions}
    <div class="warning">
      ${t("product.preparation.instructions.warning")}
    </div>`;
};

const medalsPaths = {
  dhaAa: "/img/products/medals/dha-aa.png",
  easyDigestion: "/img/products/medals/easy-digestion.png",
  gos: "/img/products/medals/gos.png",
  hidroProtein: "/img/products/medals/hidro-protein.png",
  noPalmOil: "/img/products/medals/no-palm-oil.png",
  carobGumGreen: "/img/products/medals/carob-gum--green.png",
  dhaAaGreen: "/img/products/medals/dha-aa--green.png",
  gosGreen: "/img/products/medals/gos--green.png",
  partiallyHydrolyzedProteinGreen:
    "/img/products/medals/partially-hydrolyzed-protein--green.png"
};

function getProducts(): Product[] {
  return [
    {
      slug: "frisolac-gold-1",
      type: "regular",
      color: "#00468f",
      isNew: false,
      text: {
        name: t("product.frisoGold1.name"),
        brand: t("product.frisoGold1.brand"),
        description: t("product.frisoGold1.description"),
        age: t("product.frisoGold1.age"),
        body: t("product.frisoGold1.body"),
        ingredients: t("product.frisoGold1.ingredients"),
        preparation: getPreparation("frisoGold1")
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-1.png",
        packshotThumb: "/img/products/packshots/friso-gold-1--thumb.png",
        table: "/img/products/tables/friso-gold-1.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "frisolac-gold-2",
      type: "regular",
      color: "#a2c035",
      isNew: false,
      text: {
        name: t("product.frisoGold2.name"),
        brand: t("product.frisoGold2.brand"),
        description: t("product.frisoGold2.description"),
        age: t("product.frisoGold2.age"),
        body: t("product.frisoGold2.body"),
        ingredients: t("product.frisoGold2.ingredients"),
        preparation: getPreparation("frisoGold2")
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-2.png",
        packshotThumb: "/img/products/packshots/friso-gold-2--thumb.png",
        table: "/img/products/tables/friso-gold-2.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "friso-gold-3",
      type: "regular",
      color: "#d76212",
      isNew: false,
      text: {
        name: t("product.frisoGold3.name"),
        brand: t("product.frisoGold3.brand"),
        description: t("product.frisoGold3.description"),
        age: t("product.frisoGold3.age"),
        body: t("product.frisoGold3.body"),
        ingredients: t("product.frisoGold3.ingredients"),
        preparation: getPreparation("frisoGold3")
      },
      image: {
        packshot: "/img/products/packshots/friso-gold-3.png",
        packshotThumb: "/img/products/packshots/friso-gold-3--thumb.png",
        table: "/img/products/tables/friso-gold-3.svg"
      },
      medals: [
        medalsPaths.easyDigestion,
        medalsPaths.noPalmOil,
        medalsPaths.gos,
        medalsPaths.dhaAa
      ]
    },
    {
      slug: "frisolac-vom-comfort-1",
      type: "special",
      color: "#579a50",
      isNew: false,
      text: {
        name: t("product.frisoVom1.name"),
        brand: t("product.frisoVom1.brand"),
        description: t("product.frisoVom1.description"),
        age: t("product.frisoVom1.age"),
        body: t("product.frisoVom1.body"),
        ingredients: t("product.frisoVom1.ingredients"),
        preparation: getPreparation("frisoVom1")
      },
      image: {
        packshot: "/img/products/packshots/friso-vom-1.png",
        packshotThumb: "/img/products/packshots/friso-vom-1--thumb.png",
        table: "/img/products/tables/friso-vom-1.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-vom-comfort-2",
      type: "special",
      color: "#1e632b",
      isNew: false,
      text: {
        name: t("product.frisoVom2.name"),
        brand: t("product.frisoVom2.brand"),
        description: t("product.frisoVom2.description"),
        age: t("product.frisoVom2.age"),
        body: t("product.frisoVom2.body"),
        ingredients: t("product.frisoVom2.ingredients"),
        preparation: getPreparation("frisoVom2")
      },
      image: {
        packshot: "/img/products/packshots/friso-vom-2.png",
        packshotThumb: "/img/products/packshots/friso-vom-2--thumb.png",
        table: "/img/products/tables/friso-vom-2.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-premature",
      type: "special",
      color: "#ba296a",
      isNew: false,
      text: {
        name: t("product.frisolacPremature.name"),
        brand: t("product.frisolacPremature.brand"),
        description: t("product.frisolacPremature.description"),
        age: t("product.frisolacPremature.age"),
        body: t("product.frisolacPremature.body"),
        ingredients: t("product.frisolacPremature.ingredients"),
        preparation: getPreparation("frisolacPremature")
      },
      image: {
        packshot: "/img/products/packshots/frisolac-premature.png",
        packshotThumb: "/img/products/packshots/frisolac-premature--thumb.png",
        table: "/img/products/tables/frisolac-premature.svg"
      },
      medals: [medalsPaths.gos, medalsPaths.dhaAa, medalsPaths.hidroProtein]
    },
    {
      slug: "frisolac-gold-sin-lactosa",
      type: "special",
      color: "#ffc72c",
      isNew: false,
      text: {
        name: t("product.frisolacGoldSinLactosa.name"),
        brand: t("product.frisolacGoldSinLactosa.brand"),
        description: t("product.frisolacGoldSinLactosa.description"),
        age: t("product.frisolacGoldSinLactosa.age"),
        body: t("product.frisolacGoldSinLactosa.body"),
        ingredients: t("product.frisolacGoldSinLactosa.ingredients"),
        preparation: getPreparation("frisolacGoldSinLactosa")
      },
      image: {
        packshot: "/img/products/packshots/frisolac-gold-sin-lactosa.png",
        packshotThumb:
          "/img/products/packshots/frisolac-gold-sin-lactosa--thumb.png",
        table: "/img/products/tables/frisolac-gold-sin-lactosa.svg"
      },
      medals: []
    },
    {
      slug: "friso-pep",
      type: "special",
      color: "#ba9cc5",
      isNew: false,
      text: {
        name: t("product.frisoPep.name"),
        brand: t("product.frisoPep.brand"),
        description: t("product.frisoPep.description"),
        age: t("product.frisoPep.age"),
        body: t("product.frisoPep.body"),
        ingredients: t("product.frisoPep.ingredients"),
        preparation: getPreparation("frisoPep")
      },
      image: {
        packshot: "/img/products/packshots/friso-pep.png",
        packshotThumb: "/img/products/packshots/friso-pep--thumb.png",
        table: "/img/products/tables/friso-pep.svg"
      },
      medals: [medalsPaths.hidroProtein, medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "friso-pep-ac",
      type: "special",
      color: "#642f6c",
      isNew: false,
      text: {
        name: t("product.frisoPepAc.name"),
        brand: t("product.frisoPepAc.brand"),
        description: t("product.frisoPepAc.description"),
        age: t("product.frisoPepAc.age"),
        body: t("product.frisoPepAc.body"),
        ingredients: t("product.frisoPepAc.ingredients"),
        preparation: getPreparation("frisoPepAc")
      },
      image: {
        packshot: "/img/products/packshots/friso-pep-ac.png",
        packshotThumb: "/img/products/packshots/friso-pep-ac--thumb.png",
        table: "/img/products/tables/friso-pep-ac.svg"
      },
      medals: [medalsPaths.hidroProtein, medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-gold-hipoalergenico",
      type: "special",
      color: "#c5b4e3",
      isNew: false,
      text: {
        name: t("product.frisolacGoldHa.name"),
        brand: t("product.frisolacGoldHa.brand"),
        description: t("product.frisolacGoldHa.description"),
        age: t("product.frisolacGoldHa.age"),
        body: t("product.frisolacGoldHa.body"),
        ingredients: t("product.frisolacGoldHa.ingredients"),
        preparation: getPreparation("frisolacGoldHa"),
      },
      image: {
        packshot: "/img/products/packshots/frisolac-gold-hipoalergenico.png",
        packshotThumb: "/img/products/packshots/frisolac-gold-hipoalergenico--thumb.png",
        table: "/img/products/tables/friso-ha-1.svg"
      },
      medals: [medalsPaths.hidroProtein, medalsPaths.gos, medalsPaths.dhaAa]
    },
    {
      slug: "frisolac-gold-comfort-ar",
      type: "special",
      color: "#8dcfd4",
      isNew: false,
      text: {
        name: t("product.frisolacGoldComfortAr.name"),
        brand: t("product.frisolacGoldComfortAr.brand"),
        description: t("product.frisolacGoldComfortAr.description"),
        age: t("product.frisolacGoldComfortAr.age"),
        body: t("product.frisolacGoldComfortAr.body"),
        ingredients: t("product.frisolacGoldComfortAr.ingredients"),
        preparation: getPreparation("frisolacGoldComfortAr")
      },
      image: {
        packshot: "/img/products/packshots/frisolac-gold-comfort-ar.png",
        packshotThumb:
          "/img/products/packshots/frisolac-gold-comfort-ar--thumb.png",
        table: "/img/products/tables/frisolac-gold-comfort-ar.svg"
      },
      medals: [
        medalsPaths.dhaAaGreen,
        medalsPaths.carobGumGreen,
        medalsPaths.gosGreen,
        medalsPaths.partiallyHydrolyzedProteinGreen
      ]
    }
  ];
}

function getProduct(slug: string) {
  const products = getProducts();
  return products.find(prod => prod.slug === slug);
}

export { getProducts, getProduct };
